import React, { useState, useEffect } from 'react';
import './WeatherDashboard.scss';
import * as homePageStore from "../../redux/store/home-page/home-page.store";

function WeatherDashboard() {
  const [rainfallStations, setRainfallStations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getRainFall();
  }, []);

  const getRainFall = async () => {
    try {
      setIsLoading(true); // Bắt đầu loading
      const response = await homePageStore.GetRainFall(); // Gọi hàm từ store
      setRainfallStations(response); // Cập nhật trạng thái
    } catch (err) {
      setError(err.message || "Có lỗi xảy ra khi tải dữ liệu lượng mưa."); // Xử lý lỗi
    } finally {
      setIsLoading(false); // Kết thúc loading
    }
  };

  const waterLevelStations = [
    { name: "Đạp Thanh", value: 53.33, unit: "m", status: "Thấp" },
    { name: "Thị trấn Ba Chẽ", value: 4.16, unit: "m", status: "Thấp" },
    { name: "Khu CN Nam Sơn", value: -0.77, unit: "m", status: "Thấp" },
    { name: "Hồ Khe Mười", value: 56.81, unit: "m", status: "Thấp" },
    { name: "Miếu Ông-Miếu Bà", value: 0.66, unit: "m", status: "Thấp" },
    { name: "Hồ Khe Lừa", value: 156.23, unit: "m", status: "Thấp" },
  ];

  const StationCard = ({ data }) => (
    <div className="station-card" style={{backgroundColor:data.color}}>
      <h3>{data.name}</h3>
      <div className="value-container">
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
        </svg>
        <span className="value">{data.sumDepth}</span>
      </div>
      <p className="status">{data.level || data.status}</p>
    </div>
  );

  if (isLoading) {
    return <div className="weather-dashboard">Loading...</div>;
  }

  if (error) {
    return <div className="weather-dashboard">Error: {error}</div>;
  }

  return (
    <div className="weather-dashboard">
      <section className="section">
        <h2>THỐNG KÊ TÌNH HÌNH CÁC TRẠM ĐO LƯỢNG MƯA</h2>
        <div className="station-grid">
          {rainfallStations.map((station, index) => (
            <StationCard key={index} data={station} />
          ))}
        </div>
      </section>

      <section className="section">
        <h2>THỐNG KÊ TÌNH HÌNH CÁC TRẠM ĐO MỰC NƯỚC</h2>
        <div className="station-grid">
          {waterLevelStations.map((station, index) => (
            <StationCard key={index} data={station} />
          ))}
        </div>
      </section>
    </div>
  );
}

export default WeatherDashboard;

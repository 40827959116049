import React from 'react';
import { Router, Switch } from 'react-router-dom';
import RouteComponent from './route-config.jsx';
import history from './common/history';
import UrlCollect from './common/url-collect';
import AppWrapper from './components/app-wrapper/app-wrapper.view';

//--- Loading
import AppLoading from './components/loading/loading.view';

//--- Layout
import LayoutDesktopView from './components/layout/layout.view.jsx';
import LayoutUserDesktopView from './components/layout/layout-user.view.jsx';
import LayoutMapPageView from './components/layout-map-page/layout-map-page.view.jsx';


//--- Homepage
import HomeDesktop from './modules/home/home.view.jsx';
import ContactDesktopView from './modules/contact/contact.view.jsx';
import PlanningMapView from './modules/planning-map-view/planning-map-view.view.tsx';
import WeatherDashboard from './modules/luong-mua/rain.view.jsx';
//-- User


//-- Tin tuc

import NewsListView from './modules/news/list.view.jsx';
import NewsDetailView from './modules/news/detail.view.jsx';

//--- Error pages
import ErrorPage500 from './modules/error-page/page-500/page-500';


import ReactGA from 'react-ga';
const TRACKING_ID = "G-SDZNVCY612";

function App() {
  ReactGA.initialize(TRACKING_ID);

  return (
    <div>
      <AppLoading />

      <Router history={history}>
        <AppWrapper>
          <Switch>
            {/* Desktop */}
            {/* <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={HomeDesktop}
              path={UrlCollect.Home}
            /> */}
            {/* User Desktop */}


            {/* Tin tuc */}
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={NewsDetailView}
              path={UrlCollect.NewsDetail + '/:id'}
            />
            
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={NewsListView}
              path={UrlCollect.NewsList}
            />
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={WeatherDashboard}
              path={UrlCollect.Home}
            />
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={NewsListView}
              path={UrlCollect.NewsList + '/:categoryId'}
            />

            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={() => <NewsDetailView isQHT />}
              path={UrlCollect.NewsDetailQHT + '/:id'}
            />
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={() => <NewsListView isQHT />}
              path={UrlCollect.NewsListQHT}
            />

            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={() => <NewsDetailView isQHCC />}
              path={UrlCollect.NewsDetailQHCC + '/:id'}
            />
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={() => <NewsListView isQHCC />}
              path={UrlCollect.NewsListQHCC}
            />

            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={() => <NewsDetailView isQHHTKT />}
              path={UrlCollect.NewsDetailQHHTKT + '/:id'}
            />
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={() => <NewsListView isQHHTKT />}
              path={UrlCollect.NewsListQHHTKT}
            />
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={ContactDesktopView}
              path={UrlCollect.Contact}
            />

            <RouteComponent
              exact
              layout={LayoutMapPageView}
              component={PlanningMapView}
              path={UrlCollect.PlanningMapViewDetail + ':id'}
            />

            {/* error pages */}
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={ErrorPage500}
              path={UrlCollect.Page500 + ':id'}
            />
          </Switch>
        </AppWrapper>
      </Router>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../core/app.store";
import Pagination from "../../components/pagination/pagination.view";
import {
  APIUrlDefault, DomainUserSide,
  NotificationMessageType,
  NotificationPosition,
} from "../../utils/configuration";
import MenuRightView from "./menu-right/menu-right.view";
import "@geedmo/yamm/src/yamm.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./list.scss";
import * as newsActions from "../../redux/store/news/news.store";
import dateformat from "dateformat";
import "./news-portal.scss";
const firstLoad = true;

function ListNewsView(props) {
  const { showLoading, isQHT, isQHCC, isQHHTKT } = props;
  const [keyword, setKeyword] = useState([]);
  const { categoryId } = props.match.params;

  const [currentPageIndexDoing, setCurrentPageIndexDoing] = useState(1);
  const currentPageSizeDoing = 4;
  const [totalItemCountDoing, setTotalItemCountDoing] = useState(0);
  const [newsCategoriesModel, setNewsCategoriesModel] = useState([]);

  const [pageCountDoing, setPageCountDoing] = useState();
  const [listHotNews, setHotNewsModel] = useState([]);
  const [listNews, setListNews] = useState([]);
  const [hotNews, setHotNews] = useState({});

  useEffect(() => {
    onGetData();
    getLookupNewsCategories();
  }, [firstLoad]);

  const onGetData = () => {
    showLoading(true);
    console.log(isQHHTKT)
    console.log(isQHT)
    console.log(isQHCC)
    Promise.all([
      onGetListHotNews({
        pageIndex: 1,
        pageSize: 5,
        // isQHT: isQHT, 
        // isQHCC: isQHCC, 
        // isQHHTKT: isQHHTKT,
      }),
      onGetListNews({
        pageIndex: currentPageIndexDoing,
        pageSize: currentPageSizeDoing,
        categoryId: categoryId,
        isQHT: isQHT,
        isQHCC: isQHCC,
        isQHHTKT: isQHHTKT,
      }),
    ])
      .then((res) => {
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const getLookupNewsCategories = () => {
    return new Promise((resolve, reject) => {
      newsActions.GetLookupNewsCategories().then((res) => {
        if (res && res.content && res.content.length > 0) {
          setNewsCategoriesModel(res.content);
        } else {
          setNewsCategoriesModel([]);
        }
        resolve(res);
      })
        .catch((err) => reject(err));
    });
  }


  const onGetListNews = (data) => {
    return new Promise((resolve, reject) => {
      newsActions.GetListNewsByCategory(data).then(
        (res) => {
          setListNews(
            res &&
              res.content &&
              res.content.items
              && res.content.items.length > 0 ? res.content.items : []
          );
          setTotalItemCountDoing(
            res && res.content && res.content.totalItemCount
              ? res.content.totalItemCount
              : 0
          );
          setPageCountDoing(
            res && res.content && res.content.pageCount
              ? res.content.pageCount
              : 0
          );
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  const onGetListHotNews = (data) => {
    return new Promise((resolve, reject) => {
      newsActions.GetListHotNews(data).then(
        (res) => {
          setHotNewsModel(
            res &&
              res.content &&
              res.content.items
              && res.content.items.length > 0 ? res.content.items : []
          );
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  const onPagingClick = (data, isClearSearch = false) => {
    onGetListNews({
      keyword: keyword,
      pageIndex: data.pageIndex,
      pageSize: currentPageSizeDoing,
      categoryId: categoryId,
      isQHT: isQHT,
      isQHCC: isQHCC,
      isQHHTKT: isQHHTKT,
    }
    );
  };

  return (
    <div className="container1">
    <div className="grid">
      <div className="main-content">
        <div className="header1">
          <h1>Danh sách tin tức </h1>
          <div className="search-container">
            {/* <input
              placeholder="Tìm kiếm..."
              type="search"
            />
            <svg className="search-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg> */}
          </div>
        </div>

        <div className="news-list">
          {listNews.map((item) => (
            <div key={item.id} className="news-card">
              <div className="card-content">
                <div className="image-container">
                  <Link to={"/tin-tuc-chi-tiet/" + item.id}>
                    <img
                      src={APIUrlDefault + item.image_Url}
                      onError={(e) =>
                        (e.target.src = require("../../assets/image/noImage.png"))
                    }
                    />
                  </Link>
                </div>
                <div className="text-content">
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                  <div className="date">{dateformat(item.created_date, "dd/mm/yyyy")}</div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="pagination">
          <p>Có {totalItemCountDoing} kết quả</p>
          {pageCountDoing && pageCountDoing > 1 && (
            <div className="pagination-controls">
              <Pagination
                totalItems={totalItemCountDoing}
                currentIndex={currentPageIndexDoing}
                pageSize={currentPageSizeDoing}
                onClick={(pageIndex) => {
                  onPagingClick({
                    pageIndex: pageIndex
                  });
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="sidebar">
        <div className="card">
          <div className="card-header">
            <h2>Tin mới</h2>
          </div>
          <div className="card-content">
            {listHotNews.map((item) => (
              <div key={item.id} className="hot-news-item">
                <div className="image-container">
                  <a href={"/tin-tuc-chi-tiet/" + item.id}>
                    <img
                      src={APIUrlDefault + item.image_Url}
                      onError={(e) =>
                        (e.target.src = require("../../assets/image/noImage.png"))
                    }
                    />
                  </a>
                </div>
                <div className="text-content">
                  <h3>{item.title}</h3>
                  <p>{dateformat(item.created_date, "dd/mm/yyyy")}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListNewsView);

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faPhoneAlt,
  faEnvelope,
  faAddressCard,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import Vilanguage from "../../languages/vi";
import './footer.scss'
const LanguageDisplay = Vilanguage;

export default class FooterView extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="footer-content">
            <h2 className="footer-title">
              CỔNG THÔNG TIN CẢNH BÁO LŨ LỤT TP HUẾ
            </h2>
            
            <div className="contact-info">
              <div className="info-item">
                <FontAwesomeIcon
                  icon={faBuilding}
                  className="icon"
                />
                <span>Cơ quan chủ quản:...............</span>
              </div>

              <div className="info-item">
                <FontAwesomeIcon 
                  icon={faAddressCard}
                  className="icon"
                />
                <span>Địa chỉ: 24 Tố Hữu, Thành phố Huế</span>
              </div>

              <div className="info-item">
                <FontAwesomeIcon 
                  icon={faPhoneAlt}
                  className="icon"
                />
                <span>
                  Hotline: 0251.1234567 / Fax: 0251.3821355
                </span>
              </div>

              <div className="info-item">
                <FontAwesomeIcon 
                  icon={faEnvelope}
                  className="icon"
                />
                <span>E-mail: tphue@gov.vn</span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

